<template>
  <div class="title">
    <h1 class="title__text">Provably Fair</h1>
    <LazySvgoPfTitle class="title__icon" />
    <div class="title__description">
      <h2 class="title__description-title">{{ $t('provablyFair.instructions.howItWorks') }}</h2>
      <p class="title__description-text">
        {{ descriptionText }}
        <span v-if="!isReadMore" class="title__description-text--read-more" @click="handleReadMore">
          {{ $t('provablyFair.instructions.readMore') }}
        </span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const changeMD = GlobalUtils.Media.changeByMedia('md');
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const isReadMore = ref(false);

const descriptionText = computed(() => {
  const { base, md, xl, rest } = {
    base: t('provablyFair.instructions.headerDescription1'),
    md: t('provablyFair.instructions.headerDescription2'),
    xl: t('provablyFair.instructions.headerDescription3'),
    rest: t('provablyFair.instructions.headerDescription4'),
  };
  if (isReadMore.value) {
    return `${base} ${md} ${xl} ${rest}`;
  }

  return `${base} ${changeMD(md, '')} ${changeXL(xl, '')}`;
});

const handleReadMore = () => {
  isReadMore.value = true;
};
</script>

<style scoped lang="scss" src="./TitleController.scss"></style>
